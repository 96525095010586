import React from "react";
import styled from "styled-components";
import { FlexColumn } from "../../styles";
import MapComponent from "./MapComponent";
import MapButtonRow from "./buttons/MapButtonRow";
import PopupComponent from "./PopupComponent";
import { useMapContext } from "./MapContext";

export const Container = styled(FlexColumn)`
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 64px;
  width: 100%;
  height: 600px;
  @media (max-width: 800px) {
    height: 750px;
    margin-bottom: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 16px;
  }
`;

function ImpactMap() {
  const { popupInfo, showPopup } = useMapContext();
  return (
    <Container>
      <MapButtonRow />
      <MapComponent />
      {showPopup && <PopupComponent project={popupInfo} />}
    </Container>
  );
}

export default ImpactMap;
