import { rest, RestHandler } from "msw";
import mockProjects from "./mockProjects";

export const fullyQualifiedURL = (path: string) => {
  return new URL(path, import.meta.env.VITE_BACKEND_BASE_URL).toString();
};

const handlers: Array<RestHandler> = [
  rest.get(fullyQualifiedURL("/dashboard/v3/:clientId"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProjects));
  }),

  rest.get(
    fullyQualifiedURL("/dashboard/v3/:clientId/impact"),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          mainImpact: [
            {
              unit: "m3 Kelp",
              value: 639475,
            },
            {
              unit: "Tree(s)",
              value: 1000,
            },
            {
              unit: "m2",
              value: 1,
            },
            {
              unit: "Tonnes CO2",
              value: 100.1219048398,
            },
          ],
          coBenefits: [
            {
              unit: "Hours of Education",
              value: 7,
            },
            {
              unit: "Hours of Employment",
              value: 29.75,
            },
          ],
        }),
      );
    },
  ),
  rest.get(
    fullyQualifiedURL("/dashboard/v3/:clientId/projects"),
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockProjects));
    },
  ),
  rest.get(
    fullyQualifiedURL("/dashboard/v3/:clientId/investments"),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            projectName: "Tropical Forest Protection, Keo Seima",
            createdAt: 1694695103899,
            unit: "Tonnes CO2",
            totalInvested: 345,
          },
          {
            projectName: "Mangrove Restoration Pakistan",
            createdAt: 1694695133605,
            unit: "Tonnes CO2",
            totalInvested: 86.1,
          },
          {
            projectName: "Kasigau Corridor REDD+ Project",
            createdAt: 1660197305538,
            unit: "Tonnes CO2",
            totalInvested: 195.01,
          },
          {
            projectName: "Culm Moor Woodland Reserve",
            createdAt: 1660206225418,
            unit: "Tree(s)",
            totalInvested: 100.000251,
          },
          {
            projectName: "Tree Planting- TIST Kenya",
            createdAt: 1710138243000,
            unit: "Tree(s)",
            totalInvested: 300,
          },
          {
            projectName: "Rimba Raya",
            createdAt: 1699683843000,
            unit: "Tonnes CO2",
            totalInvested: 456,
          },
          {
            projectName: "Mangrove Planting, Maroalika",
            createdAt: 1659335043000,
            unit: "Tree(s)",
            totalInvested: 1100,
          },
        ]),
      );
    },
  ),
];

export default handlers;
