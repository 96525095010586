import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import worker from "./mocks/browser";
import RouterWrapper from "./RouterWrapper";

Sentry.init({
  dsn: import.meta.env.PROD
    ? "https://a4dab33225fee66a2b15f55cc24dafe2@o4507809196802048.ingest.de.sentry.io/4507809208139856"
    : "https://5ef69ec7529b7319e919be568f22dff1@o4507809196802048.ingest.de.sentry.io/4507810017050704",
  environment: import.meta.env as unknown as string,
  release: `dashboard@${import.meta.env.VITE_APP_VERSION as string}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [
    /^\//,
    /^https:\/\/backend\.(production|qa)\.almond\.io\/dashboard\/v3/,
  ],
  tracesSampleRate: import.meta.env.PROD ? 0.2 : 1.0,
  replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1.0,
  replaysOnErrorSampleRate: import.meta.env.PROD ? 0.5 : 1.0,
});

function prepare() {
  if (import.meta.env.VITE_APP_ENV === "msw-dev") {
    return worker.start();
  }
  return Promise.resolve();
}

prepare().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );
  root.render(
    <React.StrictMode>
      <RouterWrapper />
    </React.StrictMode>,
  );
});

reportWebVitals();
