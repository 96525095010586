import { fromLonLat } from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from "ol/control";
import { ProjectResponse } from "../common/useFetchProjects";

export const initialCenterCoordinates: [number, number] = [23.91, 16.2];
export const initialCoordMercator = fromLonLat(initialCenterCoordinates);
export const initialZoom = -10;
export const projectionForCenter = "EPSG:3857";

const url =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const satelliteLayer = new TileLayer({
  preload: Infinity,
  maxZoom: 17,
  source: new XYZ({
    url,
    attributions: `ESRI, ${url}`,
  }),
});

export const mapMobile = new Map({
  target: "map-container",
  layers: [satelliteLayer],
  controls: [],
  view: new View({
    center: initialCoordMercator,
    zoom: 4,
    maxZoom: 17,
    projection: projectionForCenter,
  }),
});

const desktopMapControls = defaultControls();

export const mapDesktop = new Map({
  target: "map-container",
  layers: [satelliteLayer],
  controls: desktopMapControls,
  view: new View({
    center: initialCoordMercator,
    zoom: initialZoom,
    maxZoom: 17,
    projection: projectionForCenter,
  }),
});

export interface MapContexts {
  mapRef: React.RefObject<Map>;
  mapProjects: ProjectResponse[];
  showMap: boolean;
  showDropdownMenu: boolean;
  setShowDropdownMenu: (show: boolean) => void;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  popupInfo: ProjectResponse;
  setPopupInfo: React.Dispatch<React.SetStateAction<ProjectResponse>>;
  isMobile: boolean;
  dataId: string;
}

export const defaultContext: MapContexts = {
  mapRef: { current: mapDesktop },
  mapProjects: [],
  showMap: false,
  showDropdownMenu: false,
  setShowDropdownMenu: () => {},
  showPopup: false,
  setShowPopup: () => {},
  popupInfo: {
    id: "",
    name: "",
    description: "",
    country: "",
    websiteUrl: "",
    images: {
      default: "",
    },
    SDG: [],
    centerCoordinates: { lat: 0, lon: 0 },
  },
  setPopupInfo: () => {},
  isMobile: false,
  dataId: "",
};
