import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { Pointer } from "ol/interaction";
import Map from "ol/Map";
import { useMediaQuery } from "react-responsive";
import {
  mapMobile,
  mapDesktop,
  MapContexts,
  defaultContext,
} from "./InitialMapValues";
import getMarkerLayer from "./map-layers/getMarkerLayer";
import useFetchProjects, { ProjectResponse } from "../common/useFetchProjects";

const MapContext = createContext<MapContexts>(defaultContext);

export function MapProvider({ children }: { children: React.ReactNode }) {
  const { clientId } = useParams<{ clientId: string }>();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const currentMap = isMobile ? mapMobile : mapDesktop;
  const mapRef = useRef<Map>(currentMap);
  const { projects: mapProjects } = useFetchProjects();
  const [showMap, setShowMap] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState<ProjectResponse>(
    defaultContext.popupInfo,
  );
  const [dataId, setDataId] = useState("");

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setTarget();
    }
  }, [clientId]);

  useEffect(() => {
    if (mapRef.current && mapProjects.length > 0) {
      const { vectorLayer, selectClick } = getMarkerLayer(
        mapProjects,
        setPopupInfo,
        setShowPopup,
      );
      const hoverInteraction = new Pointer({
        handleMoveEvent: (event) => {
          const { pixel } = event;
          const hit = currentMap.hasFeatureAtPixel(pixel);
          const targetElement = currentMap.getTargetElement();
          targetElement.style.cursor = hit ? "pointer" : "default";
        },
      });
      mapRef.current.addLayer(vectorLayer);
      mapRef.current.addInteraction(hoverInteraction);
      mapRef.current.addInteraction(selectClick);
      const dataIdToSet = "markers";
      setDataId(dataIdToSet);

      vectorLayer.setZIndex(2);

      setShowMap(true);
    } else {
      setShowMap(false);
    }
  }, [mapProjects, currentMap]);

  const contextValue = useMemo(
    () => ({
      mapRef,
      mapProjects,
      showMap,
      showDropdownMenu,
      setShowDropdownMenu,
      showPopup,
      setShowPopup,
      popupInfo,
      setPopupInfo,
      isMobile,
      dataId,
    }),
    [
      mapProjects,
      showMap,
      showDropdownMenu,
      popupInfo,
      showPopup,
      isMobile,
      dataId,
    ],
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
}

export default MapProvider;

export const useMapContext = () => {
  return useContext(MapContext);
};
