import React from "react";
import styled from "styled-components";
import { FlexColumn, FlexRow, Button, ATag, StyledH5 } from "../../styles";
import { Header } from "../project/ProjectCard";
import { ProjectResponse } from "../common/useFetchProjects";
import { useMapContext } from "./MapContext";
import useTruncateText from "../common/useTruncateText";

const Container = styled(FlexColumn)`
  position: relative;
  z-index: 8;
  width: 300px;
  left: -25%;
  bottom: -30%;
  height: 330px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  padding: "8px 8px 16px";
  @media (max-width: 450px) {
    width: 300px;
    left: -90%;
    top: 53%;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    width: 197px;
    left: -50%;
    top: 54%;
    height: 292px;
  }
  @media (min-width: 801px) and (max-width: 1198px) {
    height: 330px;
    width: 300px;
    left: -40%;
  }
  @media (min-width: 1199px) {
    left: -27%;
  }
`;
const ClickableContainer = styled(FlexColumn)`
  position: relative;
  width: 100%;
  @media (min-width: 451px) and (max-width: 800px) {
    height: 250px;
  }
`;

const ImageHeader = styled(Header)`
  height: 220px;
  @media (max-width: 450px) {
    padding: 0;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    height: 140px;
  }
`;

const ProjectName = styled(StyledH5)`
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 900;
  word-break: break-all;
  margin: 12px;
  @media (min-width: 451px) and (max-width: 800px) {
    height: 28px;
    margin-top: 30px;
  }
`;

const ButtonContainer = styled(FlexRow)`
  position: relative;
  justify-content: end;
  width: 95%;
  overflow: hidden;
  white-space: normal;
  margin: 22px 0 4px 0;
  @media (min-width: 451px) and (max-width: 800px) {
    margin: -5px 0 4px 0;
  }
`;
const LearnMoreButton = styled(Button)`
  border: 1px solid #000;
  width: 100px;
  height: 28px;
  font-size: 0.75rem;
  box-shadow: none;
  &:hover {
    font-weight: 300;
    color: #ffffff;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    height: 27px;
    font-size: 0.7rem;
    width: 95px;
  }
`;
const CloseButton = styled(Button)`
  width: 70px;
  height: 32px;
  font-size: 0.8rem;
  box-shadow: none;
  &:hover {
    background-color: #ffffff;
    color: #40e4ab;
    font-weight: 600;
  }
`;

function PopupComponent({ project }: { project: ProjectResponse }) {
  const text = project.name;
  const maxLength = 70;
  const isTooLong = text.length > maxLength;
  const truncatedProjectName = useTruncateText(text, maxLength, isTooLong);

  const { setShowPopup } = useMapContext();
  if (!project) return null;

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const isNotFound = project.images.default.includes("not-found");
  const imageTestId = isNotFound
    ? `${project.images.default}-image-not-found`
    : `${project.images.default}-image`;

  return (
    <Container className="popup">
      <ClickableContainer className="clickable-area-container">
        <ATag
          href={`https://earthly.org/${project.websiteUrl}`}
          target="_blank"
          rel="noreferrer"
        >
          <ImageHeader
            image={project.images.default}
            isNotFound={project.images.default.includes("not-found")}
            data-testid={imageTestId}
          />
          <ProjectName>{truncatedProjectName}</ProjectName>
        </ATag>
      </ClickableContainer>
      <ButtonContainer>
        <CloseButton
          onClick={handleClosePopup}
          data-testid="close-popup-button"
        >
          Close
        </CloseButton>
        <LearnMoreButton data-testid="learn-more-button">
          <ATag
            href={`https://earthly.org/${project.websiteUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </ATag>
        </LearnMoreButton>
      </ButtonContainer>
    </Container>
  );
}

export default PopupComponent;
