import React from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { Button } from "../../../styles";
import { useMapContext } from "../MapContext";
import { initialCoordMercator } from "../InitialMapValues";

const ZoomOutButton = styled(Button)`
  position: absolute;
  margin-top: 10px;
  margin-right: 30px;
  right: 0;
  width: 180px;
  @media (max-width: 800px) {
    margin-top: 17px;
    width: 110px;
    height: 27px;
    font-size: 0.8rem;
  }
  @media (min-width: 1488px) and (max-width: 1487px) {
    margin-right: 40px;
  }
  @media (min-width: 1488px) {
    margin-right: 180px;
  }
`;

export const StyledMapTooltip = styled(Tooltip)`
  && {
    background-color: #23a477e3;
    color: white;
    border-radius: 16px;
    font-size: 12px;
  }
`;

function ViewAllButton() {
  const { mapRef, setShowDropdownMenu, setShowPopup } = useMapContext();

  const handleViewAll = () => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      view.animate({
        center: initialCoordMercator,
        zoom: 1,
        duration: 2000,
      });
      setShowDropdownMenu(false);
      setShowPopup(false);
    }
  };
  return (
    <ZoomOutButton data-tooltip-id="zoom-out" onClick={handleViewAll}>
      View All
      <StyledMapTooltip
        id="zoom-out"
        place="bottom"
        content="Zoom out to see full map"
        opacity={1}
      />
    </ZoomOutButton>
  );
}
export default ViewAllButton;
