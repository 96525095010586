import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useErrorBoundary } from "react-error-boundary";
import api from "../../api";

export type Coordinates = { lat: number; lon: number };

export type ProjectResponse = {
  id: string;
  name: string;
  description: string;
  country: string;
  websiteUrl: string;
  outcomesPerUnitOfSale?: Array<{
    unit: string;
    value: number;
    isUnitOfSale: boolean;
  }>;
  images: {
    default: string;
  };
  SDG: Array<number>;
  centerCoordinates: Coordinates;
};
const useFetchProjects = () => {
  const { clientId } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [projects, setProjects] = useState<Array<ProjectResponse>>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (clientId) {
      const fetchProjects = async (): Promise<
        AxiosResponse<ProjectResponse[]>
      > => {
        const response = await api.getProjects(clientId);
        return response;
      };
      fetchProjects()
        .then((res) => {
          const sortedProjects = res.data.sort((a, b) =>
            a.name < b.name ? -1 : 1,
          );
          setProjects(sortedProjects);
        })
        .catch(() => {
          const errorMsg = "Sorry, this client can’t be found.";
          setError(errorMsg);
          showBoundary(errorMsg);
        });
    }
  }, [clientId, showBoundary, error]);

  return { projects, showBoundary, error };
};

export default useFetchProjects;
