/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { FlexColumn, StyledH2 } from "../../styles";
import TransactionLedgerTable from "./TransactionLedgerTable";
import api from "../../api";

type Transactions = {
  projectName: string;
  createdAt: number;
  unit: string;
  totalInvested: number;
};

type TransactionsResponse = Array<Transactions>;

const TransactionsHeader = styled(StyledH2)`
  @media (max-width: 428px) {
    padding-top: 24px;
    font-size: 24px;
  }

  @media (min-width: 429px) {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 85px;
  }
`;

const StyledWrapper = styled(FlexColumn)`
  height: auto;
  padding-left: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 8px;
  background-color: #f7f7f7;
  border-radius: 16px;
`;

function TransactionLedger() {
  const { clientId } = useParams<{ clientId: string }>();
  const { showBoundary } = useErrorBoundary();
  const [transactionLedgerList, setTransactionLedgerList] =
    useState<TransactionsResponse>([]);

  useEffect(() => {
    if (clientId) {
      const fetchTransactions = async () => {
        const response = await api.getTransactions(clientId);
        setTransactionLedgerList(response.data as TransactionsResponse);
      };
      fetchTransactions().catch((error) => {
        if (error.response && error.response.status === 403) {
          setTransactionLedgerList([]);
        } else {
          showBoundary(
            "Sorry, this page can’t be loaded right now. Our technical team has been automatically notified and will be looking into this.",
          );
        }
      });
    }
  }, [clientId, showBoundary]);

  if (transactionLedgerList.length === 0) return null;

  return (
    <StyledWrapper>
      <TransactionsHeader data-testid="transaction-test">
        Transaction History
      </TransactionsHeader>
      <TransactionLedgerTable data={transactionLedgerList} />
    </StyledWrapper>
  );
}

export default TransactionLedger;
