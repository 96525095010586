import React, { useEffect } from "react";
import styled from "styled-components";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getName } from "country-list";
import { useErrorBoundary } from "react-error-boundary";
import {
  FlexColumn,
  FlexRow,
  StyledBodyTextSmall,
  StyledH2,
  StyledH4,
} from "../../styles";
import ProjectCard from "./ProjectCard";
import api from "../../api";
import { ProjectResponse } from "../common/useFetchProjects";

const Container = styled(FlexColumn)`
  background-color: #f7f7f7;
  padding: 32px 8px 64px 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 368px;
`;

const CardListContainerOuter = styled(FlexColumn)`
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  max-width: 1168px;
`;

const CardListContainerCollapsed = styled.div<{ noOfCards: number }>`
  display: grid;
  grid-gap: 16px;
  justify-content: start;
  align-content: start;

  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 840px) and (max-width: 1231px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1232px) {
    grid-template-columns: ${(props) => {
      if (props.noOfCards === 2) return "repeat(2, 1fr)";
      if (props.noOfCards === 3) return "repeat(3, 1fr)";
      return "1fr";
    }};
`;

const CardListContainerExpanded = styled.div`
  display: grid;
  grid-gap: 32px;
  justify-content: start;
  align-content: start;

  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 840px) and (max-width: 1231px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1232px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LoadMoreButtonContainer = styled(FlexRow)`
  padding-top: 48px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LoadMoreButton = styled.button`
  border-radius: 18px;
  border: 1px solid #201f1f;
  width: 111px;
  height: 32px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: transparent;
  cursor: pointer;
  :hover {
    background: rgba(90, 90, 90, 0.05);
  }
  :active {
    background: rgba(90, 90, 90, 0.2);
  }
`;

const SubHeader = styled(StyledH2)`
  padding-bottom: 32px;
  margin-left: 85px;
  align-self: start;

  @media (max-width: 524px) {
    margin-left: 0;
    align-self: center;
  }
`;

const SubHeaderMobile = styled(StyledH4)`
  padding-bottom: 32px;
  margin-left: 0;
  align-self: start;
`;

const generateLocation = (code: string): string | undefined => {
  if (!code) return undefined;
  if (code === "GB") return "United Kingdom";
  return getName(code);
};

function ProjectList() {
  const { clientId } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [firstRowProjects, setFirstRowProjects] = React.useState<
    Array<ProjectResponse>
  >([]);
  const [allProjects, setAllProjects] = React.useState<Array<ProjectResponse>>(
    [],
  );
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const isMobile = useMediaQuery({ query: "(max-width: 428px" });

  useEffect(() => {
    if (clientId) {
      const fetchProjects = async (): Promise<
        AxiosResponse<Array<ProjectResponse>>
      > => {
        const response = await api.getProjects(clientId);
        return response;
      };

      fetchProjects()
        .then((res) => {
          const sortedProjects = res.data.sort((a, b) =>
            a.name < b.name ? -1 : 1,
          );
          if (sortedProjects.length > 3) {
            setFirstRowProjects(sortedProjects.slice(0, 3));
            setAllProjects(sortedProjects);
          } else {
            setFirstRowProjects(sortedProjects);
          }
        })
        .catch(() => {
          showBoundary(
            "Sorry, this page can’t be loaded right now. Our technical team have been automatically notified and will be looking into this.",
          );
        });
    }
  }, [clientId, showBoundary]);

  return (
    <Container>
      {isMobile ? (
        <SubHeaderMobile>Supported projects</SubHeaderMobile>
      ) : (
        <SubHeader>Supported projects</SubHeader>
      )}
      <CardListContainerOuter>
        <CardListContainerCollapsed noOfCards={firstRowProjects.length}>
          {firstRowProjects &&
            !showAll &&
            firstRowProjects.map((project) => (
              <ProjectCard
                key={project.id}
                sdg={project.SDG.sort((a, b) => a - b)}
                name={project.name}
                description={project.description}
                location={generateLocation(project.country)}
                imageUrl={project.images.default}
                websiteUrl={project.websiteUrl}
              />
            ))}
        </CardListContainerCollapsed>
        {allProjects.length > 0 && !showAll && (
          <LoadMoreButtonContainer>
            <LoadMoreButton type="button" onClick={() => setShowAll(true)}>
              <StyledBodyTextSmall>Load more</StyledBodyTextSmall>
            </LoadMoreButton>
          </LoadMoreButtonContainer>
        )}
        <CardListContainerExpanded>
          {allProjects.length > 0 &&
            showAll &&
            allProjects.map((project) => (
              <ProjectCard
                key={project.id}
                sdg={project.SDG.sort((a, b) => a - b)}
                name={project.name}
                description={project.description}
                location={generateLocation(project.country)}
                imageUrl={project.images.default}
                websiteUrl={project.websiteUrl}
              />
            ))}
        </CardListContainerExpanded>
      </CardListContainerOuter>
    </Container>
  );
}

export default ProjectList;
