import React from "react";
import styled from "styled-components";

interface StyledIconProps {
  size: "large" | "small";
}

const StyledIcon = styled.img<StyledIconProps>``;

function Icon({
  icon = "",
  description = "",
  size = "small",
}: {
  icon: string | undefined;
  description: string | undefined;
  size?: "large" | "small";
}) {
  return <StyledIcon size={size} src={icon} alt={`${description} icon`} />;
}

export default Icon;
