import React from "react";
import styled from "styled-components";
import { FlexColumn, Button } from "../../../styles";
import { useMapContext } from "../MapContext";
import downChevron from "../../../assets/icons/chevron-down.png";
import projectIcon from "../../../assets/icons/clipboard-list.png";
import FlyToButton from "./FlyToButton";
import ViewAllButton, { StyledMapTooltip } from "./ViewAllButton";

const DropdownWrapper = styled(FlexColumn)`
  position: absolute;
  align-items: start;
  margin-top: 30px;
  margin-left: 5%;
  justify-content: center;
  max-height: 600px;
  border-radius: 18px;
  z-index: 10;
  flex: 1;
  @media (min-width: 377px) and (max-width: 428px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (min-width: 429px) and (max-width: 1450px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 1489px) {
    padding-left: 144px;
    padding-right: 144px;
    margin-left: 1%;
  }
`;

const DropdownButton = styled(Button)`
  position: absolute;
  left: 10%;
  word-break: break-all;
  @media (max-width: 800px) {
    width: 135px;
    height: 27px;
    font-size: 0.8rem;
    left: 0px;
  }
`;
const DropdownMenuItems = styled.div`
  position: absolute;
  top: calc(100% + 23px);
  left: 10%;
  max-height: 520px;
  overflow: scroll;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  word-break: break-all;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 800px) {
    font-size: 0.8rem;
    left: -10%;
    top: calc(100% + 17px);
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Icon = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

function MapButtonRow() {
  const { mapProjects, showDropdownMenu, setShowDropdownMenu } =
    useMapContext();

  const handleDropdownClick = () => {
    setShowDropdownMenu(!showDropdownMenu);
  };

  return (
    <>
      <DropdownWrapper>
        <DropdownButton
          data-tooltip-id="click-project"
          onClick={handleDropdownClick}
        >
          <StyledMapTooltip
            id="click-project"
            place="bottom"
            content="Select a project to visit on your map."
            opacity={1}
          />
          <Icon src={projectIcon} alt="projects-icon" />
          Project <Icon src={downChevron} alt="down chevron icon" />
        </DropdownButton>
        {showDropdownMenu && (
          <DropdownMenuItems>
            {mapProjects.map((project) => (
              <FlyToButton
                data-tooltip-id="click-project"
                key={project.id}
                project={project}
              />
            ))}
          </DropdownMenuItems>
        )}
      </DropdownWrapper>
      <ViewAllButton />
    </>
  );
}

export default MapButtonRow;
