import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Select } from "ol/interaction";
import { click } from "ol/events/condition";
import { Circle, Fill, Icon, Stroke, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import whiteMarker from "../../../assets/icons/markerWithDesign.png";
import { ProjectResponse } from "../../common/useFetchProjects";

const getMarkerLayer = (
  projects: ProjectResponse[],
  setPopupInfo: (popupInfo: ProjectResponse) => void,
  setShowPopup: (showPopup: boolean) => void,
) => {
  const vectorSource = new VectorSource();

  const features = projects.map((project) => {
    const targetCenter = fromLonLat([
      project.centerCoordinates.lon,
      project.centerCoordinates.lat,
    ]);
    const iconFeature = new Feature({
      geometry: new Point(targetCenter),
      id: `marker-${project.id}`,
      project,
    });

    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: whiteMarker,
        height: 50,
      }),
    });

    const pointStyle = new Style({
      image: new Circle({
        radius: 3,
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "white",
          width: 0.5,
        }),
      }),
    });

    iconFeature.setStyle([pointStyle, iconStyle]);

    return iconFeature;
  });

  vectorSource.addFeatures(features);

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    className: "vector-layer",
  });

  vectorLayer.set("data-testid", "vector-layer");

  const selectClick = new Select({
    condition: click,
    layers: [vectorLayer],
  });

  const selectedIconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: whiteMarker,
      height: 50,
    }),
  });

  const selectedPointStyle = new Style({
    image: new Circle({
      radius: 3,
      fill: new Fill({
        color: [64, 228, 171],
      }),
      stroke: new Stroke({
        color: [64, 228, 171],
        width: 0,
      }),
    }),
  });

  selectClick.on("select", (event) => {
    const selectedFeature = event.selected[0];
    if (selectedFeature) {
      const project = selectedFeature.get("project") as ProjectResponse;
      selectedFeature.setStyle([selectedPointStyle, selectedIconStyle]);
      setPopupInfo(project);
      setShowPopup(true);
    }
  });

  return {
    vectorLayer,
    selectClick,
  };
};

export default getMarkerLayer;
