import React, { useState } from "react";
import styled from "styled-components";
import { fromLonLat } from "ol/proj";
import { FlexColumn } from "../../../styles";
import { ProjectResponse } from "../../common/useFetchProjects";
import { useMapContext } from "../MapContext";

const DropdownItem = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: white;
  word-break: break-all;
  border: grey solid 2px;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  width: 200px;
  z-index: 10;
  &:hover {
    background-color: #40e4ab;
    color: #ffffff;
    font-weight: 600;
    img {
      filter: invert(1);
    }
  }
`;

function FlyToButton({ project }: { project: ProjectResponse }) {
  const { mapRef, setShowDropdownMenu, setShowPopup } = useMapContext();
  const [flyToCoordinates, setFlyToCoordinates] = useState([
    -0.05813303, 51.519018,
  ]);

  const handleButtonClick = () => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const { lon } = project.centerCoordinates;
      const { lat } = project.centerCoordinates;
      const checkIfValidCoordinate = (coord: number, type: "lat" | "lon") => {
        if (typeof coord !== "number" || Number.isNaN(coord)) return false;
        if (type === "lat" && (coord < -90 || coord > 90)) return false;
        if (type === "lon" && (coord < -180 || coord > 180)) return false;
        return true;
      };
      let coordinatesToFlyTo = flyToCoordinates;

      if (
        checkIfValidCoordinate(lat, "lat") &&
        checkIfValidCoordinate(lon, "lon")
      ) {
        coordinatesToFlyTo = [lon, lat];
        setFlyToCoordinates(coordinatesToFlyTo);
      }
      try {
        const targetCenter = fromLonLat(coordinatesToFlyTo);
        view.animate({
          center: targetCenter,
          zoom: 12,
          duration: 2000,
        });
        setShowDropdownMenu(false);
        setShowPopup(false);
      } catch (error) {
        throw new Error("Error during map animation:");
      }
    }
  };

  return (
    <DropdownItem onClick={handleButtonClick}>{project.name}</DropdownItem>
  );
}

export default FlyToButton;
