import * as React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { FlexColumn } from "./styles";
import Impact from "./components/impact";
import ProjectList from "./components/project";
import CoBenefits from "./components/co-benefits";
import api from "./api";
import TransactionLedger from "./components/transaction-ledger";
import ImpactMap from "./components/impact-map";
import { useMapContext } from "./components/impact-map/MapContext";

const Container = styled(FlexColumn)`
  flex: 1;

  @media (min-width: 377px) and (max-width: 428px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width: 429px) and (max-width: 1488px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 1489px) {
    padding-left: 144px;
    padding-right: 144px;
  }
`;

type ImpactType = {
  unit: string;
  value: number;
};

type ImpactResponse = {
  mainImpact: Array<ImpactType>;
  coBenefits: Array<ImpactType>;
};

function Body() {
  const { clientId } = useParams();
  const { showMap } = useMapContext();
  const { showBoundary } = useErrorBoundary();

  const [impact, setImpact] = React.useState<ImpactResponse>({
    mainImpact: [],
    coBenefits: [],
  });

  useEffect(() => {
    if (clientId) {
      const fetchImpact = async (): Promise<AxiosResponse<ImpactResponse>> => {
        const response = await api.getImpact(clientId);
        return response;
      };

      fetchImpact()
        .then((res: AxiosResponse<ImpactResponse>) => {
          setImpact(res.data);
        })
        .catch(() => {
          showBoundary(
            "Sorry, this page can’t be loaded right now. Our technical team have been automatically notified and will be looking into this.",
          );
        });
    }
  }, [clientId, showBoundary]);

  return (
    <Container>
      <Impact mainImpactList={impact.mainImpact} />
      <CoBenefits coBenefitList={impact.coBenefits} />
      {showMap && <ImpactMap />}
      <ProjectList />
      <TransactionLedger />
    </Container>
  );
}

export default Body;
